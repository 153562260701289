import React, { useState, useEffect } from 'react';
import { ScrollView, View, StyleSheet, Text, TouchableOpacity, Platform, Linking } from 'react-native';
import { DataTable, Portal, Provider, Checkbox, Button, Tooltip, IconButton, MD3Colors } from 'react-native-paper';
import ButtonControl from './Button';
import ToastMessage from './Toast';
import { deleteItem } from '../service/dataOperations';
import PaperDialog from './PaperDialog';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import ControlStyles from './ControlStyles';
import ConfirmBox from '../../../Common/ConfirmBox';

interface Props {
  data: Array<any>;
  columns: Array<any>;
  //yearOptions: Array<any>;
  //handleYearFilterSelect: (val: string) => void;
  //selectedYear: string;
  updateFormState: () => void;
  updateFormMode: (val: string) => void;
  handleRowPress: (val: Number[]) => void;
  ViewPressAction: (val: any) => void;
  EditPressAction: (val: any) => void;
  NewPressAction: () => void;
  siteId: string;
  logTitle: string;
  isConsolidatedTable: boolean
}

const DT: React.FC<Props> = (props) => {

  const optionsPerPage: number[] = [10, 20, 50];
  const [data, setData] = useState<Array<any>>(props.data.sort((a, b) => b.ID - a.ID));
  useEffect(() => {
    if (props.logTitle) {//props.logTitle === "Environmental Issues Observations"
      const dataWithLinksAndFiles = props.data.sort((a, b) => b.ID - a.ID).map(item => {
        // Parse the Attachments column
        let attachments = item.Attachments &&  JSON.parse(item.Attachments || "[]");

        // Only parse Location if it contains the string "coords"
        let locationLink = item.Location && item.Location
        if (item.Location && item.Location.includes("coords")) {
          let location = JSON.parse(item.Location || "{}");
          if (location?.coords) {
            const { latitude, longitude } = location.coords;
            locationLink = `https://maps.google.com/?q=${latitude},${longitude}`;
          }
        }
        return {
          ...item,
          // Attachments: attachments.length > 0
          //   ? attachments.map((att: any) => att.fileName).join(', ')
          //   : '',
          Attachments: attachments && attachments.length > 0
            ? attachments.length  // Return the count of files
            : 0,

          // Form link only if the "coords" substring exists and coordinates are parsed
          Location: locationLink
            ? `${locationLink}`
            : '' // Show empty string if no location or no coords
        };
      });

      setData(dataWithLinksAndFiles.sort((a, b) => b.ID - a.ID));
    }
    else setData(props.data.sort((a, b) => b.ID - a.ID));
  }, [props])
  const [columns, setColumns] = useState<Array<any>>(props.columns.sort((a, b) => a.order - b.order));
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(optionsPerPage[0]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();

  function handleRowClick(row: any) {
    const rowId: number = row.ID;
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
    props.handleRowPress(row);
  };
  function handleCheckboxPress(row: any) {

    const rowId: number = row.ID;
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
    props.handleRowPress(row);
  };
  function handleNew() {
    props.updateFormMode("New");
    //props.NewPressAction();
  };
  function handleEditPress(sRows: any) {
    if (sRows.length === 0) {
      setToastVisibility(true);
      setToastText("Please select atleast one item to view.")

    }
    else {
      props.updateFormMode("Edit");
      props.ViewPressAction(sRows);
    }
  };
  function handleViewPress(sRows: any) {
    if (sRows.length === 0) {
      setToastVisibility(true);
      setToastText("Please select atleast one item to view.")
    }
    else {
      props.updateFormMode("View");
      props.ViewPressAction(sRows);

    }
  };
  async function handleDeletePress(sRows: any) {
    setShowConfirmation(true);
  };
  async function handleConfirmDelete() {
    const accessToken = await getAccessToken();

    for (const item of selectedRows) {
      const result = await deleteItem(props.logTitle, accessToken, clientAPIURL, props.siteId, item);
      if (result.status === 200) {
        setToastText("Deleted successfully.")
        setToastVisibility(true);
      }
      else if (result.status === 401 || result.statusText === "Unauthorized") {
        setToastText("Delete Failed: You are not authorized to perform this action.")
        setToastVisibility(true);
      }


    }
    setShowConfirmation(false);
  };
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  function handleCancelDelete() {
    // Close the confirmation dialog without performing any action
    setShowConfirmation(false);
  };
  function handleYearSelect(val: string) {
    //console.log(val)
    //props.handleYearFilterSelect(val);
  };
  const GoToLocation = (locationLink: any) => {
    //debugger;
    console.log(locationLink);
    if (locationLink) {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        Linking.openURL(locationLink);
      }
      else if (Platform.OS === 'web') {
        const googleMapLink = locationLink && locationLink;
        googleMapLink && window.open(googleMapLink, '_blank');
      }
      //Linking.openURL(locationLink); // Open Google Maps with the coordinates
    }
  };
  return (
    <View>
      <View style={ControlStyles.ribbonContainer}>
        {props.isConsolidatedTable === true ?

          <ScrollView horizontal style={ControlStyles.ribbonScrollView}>
            <View style={ControlStyles.buttonContainer}>
              <ButtonControl icon="eye-outline" mode="contained" onPress={() => handleViewPress(selectedRows)} Title='View' disabled={selectedRows.length === 1 ? false : true} />
            </View>
          </ScrollView>
          :
          <ScrollView horizontal style={ControlStyles.ribbonScrollView}>
            <View><View style={ControlStyles.buttonContainer}>
              <ButtonControl icon="plus" mode="contained" onPress={() => handleNew()} Title='New' disabled={false} />
            </View></View>
            <View style={ControlStyles.buttonDivider}></View>
            <View><View style={ControlStyles.buttonContainer}>
              <ButtonControl icon="eye-outline" mode="contained" onPress={() => handleViewPress(selectedRows)} Title='View' disabled={selectedRows.length === 1 ? false : true} />
            </View></View>
            {props.logTitle === 'Report Request' ? null : <View style={ControlStyles.buttonDivider}></View>}
              {props.logTitle === 'Report Request' ? null : <View><View style={ControlStyles.buttonContainer}>
                <ButtonControl icon="pencil" mode="contained" onPress={() => handleEditPress(selectedRows)} Title='Edit' disabled={selectedRows.length === 1 ? false : true} />
              </View></View>}
              {props.logTitle === 'Report Request' ? null : <View style={ControlStyles.buttonDivider}></View>}
              {props.logTitle === 'Report Request' ? null :  <View><View style={ControlStyles.buttonContainer}>
                <ButtonControl icon="delete-outline" mode="contained" onPress={() => handleDeletePress(selectedRows)} Title='Delete' disabled={selectedRows.length === 0 ? true : false} />
              </View></View>}
          </ScrollView>
        }
      </View>
      <View>
        <ToastMessage
          show={toastVisibility}
          actionLabel="Dismiss"
          toastMessage={toastText}
          duration={3000}
          onDismiss={handleToastDismiss}
        />
      </View>
      {showConfirmation && (
        <View>

          {/* <PaperDialog
                  show={showConfirmation}
                  title="Confirmation"
                  message="Are you sure you want to proceed to delete selected items?"
                  ok="Proceed"
                  no="Cancel"
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                /> */}
          <ConfirmBox title={"Confirmation"} message={"Are you sure you want to proceed to delete selected items?"} yesCallback={handleConfirmDelete} noCallback={handleCancelDelete} />

        </View>
      )}

      <View style={ControlStyles.tableContainer}>

        <DataTable>
          <ScrollView horizontal contentContainerStyle={{ flexDirection: 'column', flexGrow: 1 }}>
            {/* Table Header */}
            <DataTable.Header style={ControlStyles.dtHeader}>
              <DataTable.Title key={""} style={[ControlStyles.dtTitle, { width: 100 }]}>
                <Text style={ControlStyles.dtHeaderText}>Select</Text>
              </DataTable.Title>
              {columns.map((column, index) => (
                <DataTable.Title
                  key={index}
                  style={[ControlStyles.dtTitle, { width: column.title.length * 15 }]}
                  sortDirection={column.title === 'Item ID' ? 'descending' : null}
                >
                  <Text style={ControlStyles.dtHeaderText}>{column.title}</Text>
                </DataTable.Title>
              ))}
            </DataTable.Header>

            {/* Table Rows */}
            {data.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((row, index) => (
              <DataTable.Row
                key={index}
                onPress={() => handleRowClick(row)}
                style={{
                  backgroundColor: selectedRows.includes(row.ID) ? '#00B8EE' : index % 2 === 0 ? 'white' : '#4BB75F',
                }}
              >
                {/* Checkbox for selecting rows */}
                <DataTable.Cell key={"checkbox"} style={[ControlStyles.dtCell, { width: 100 }]}>
                  <Checkbox.Android
                    status={selectedRows.includes(row.ID) ? 'checked' : 'unchecked'}
                    onPress={() => handleCheckboxPress(row)}
                  />
                </DataTable.Cell>

                {/* Data Columns */}
                {columns.map((column, colIndex) => (
                  <DataTable.Cell key={colIndex} style={[ControlStyles.dtCell, { width: column.title.length * 15 }]}>
                    {column.columnName === 'Location' && row[column.columnName] ? (
                      row[column.columnName] && row[column.columnName].includes("https") ? (
                        <TouchableOpacity onPress={() => GoToLocation(row[column.columnName])}>
                          <IconButton
                            icon="map-marker-path"
                            iconColor={MD3Colors.error10}
                            size={20}
                          />
                        </TouchableOpacity>
                      ) : <Text style={ControlStyles.dtCellText}>{row[column.columnName]}</Text>
                    ) :
                      column.columnName === 'Attachments' ?
                        row[column.columnName] > 0 ?
                          <View style={{ flexDirection: 'row' }}>
                            <View>
                              <Tooltip title={row[column.columnName] +" files attached"}>
                                <IconButton
                                  icon="paperclip"
                                  iconColor={MD3Colors.error10}
                                  size={20}
                                />
                              </Tooltip>
                            </View>
                            <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <Text>{row[column.columnName]}</Text>
                            </View>
                          </View>
                          :
                          null
                        :
                        (
                          <Text style={ControlStyles.dtCellText}>{row[column.columnName]}</Text>
                        )
                    }
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </ScrollView>
          <DataTable.Pagination
            numberOfItemsPerPageList={optionsPerPage}
            numberOfItemsPerPage={itemsPerPage}
            page={page}
            numberOfPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={(page) => setPage(page)}
            onItemsPerPageChange={setItemsPerPage}
            showFastPaginationControls
            selectPageDropdownLabel={'Rows per page'}
            label={`${page * itemsPerPage + 1}-${Math.min(
              (page + 1) * itemsPerPage,
              data.length
            )} of ${data.length}`}
            style={{ alignSelf: 'flex-start' }}
          />
        </DataTable>

      </View>
    </View>
  );

}

export default DT;