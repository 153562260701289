import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, useWindowDimensions, ScrollView, Text, Platform, TouchableOpacity } from 'react-native';
import { useForm, Controller, useWatch } from 'react-hook-form';
import TextField from '../controls/TextBox';
import NumberField from '../controls/NumberField';
import PaperDates from '../controls/datePicker-paperDates';
import ButtonControl from '../controls/Button';
import { createItem, updateItem } from '../service/dataOperations';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import ToastMessage from '../controls/Toast';
import formCustomStyles from './formStyles';
import ReactDropdownSingleSelect from '../controls/Dropdown-Single';

interface PaperFormProps {
  formMode: string;
  fields: Array<any>;
  selectedRow: Array<any>;
  closeForm: () => void;
  siteId: string;
  logTitle: string;
  updateFormState: () => void;
  ItemCreated: (Item: Array<any>) => void;
}

const ReportRequestForm: React.FC<PaperFormProps> = (props) => {

  const [formStyle, setFormStyle] = useState(styles.formContainerMobile);
  const [formMode, setFormMode] = useState(props.formMode);
  const [yearChoices, setYearChoices] = useState(undefined)
  const [fields, setFields] = useState(props.fields);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [itemId, setItemId] = useState(null);
  const [renderKey, setRenderKey] = useState(0);
  //const [selectedTemplate, setSelectedTemplate] = useState(null);
  let item: any;
  const { reset, register, setValue, handleSubmit, control, resetField, formState: { errors } } = useForm();
  const selectedTemplate = useWatch({ control, name: "Template" });
  let formFields = props.fields;
  useEffect(() => {
    const currentYear = new Date().getFullYear();

    // Update Year field dynamically
    const updatedFields = props.fields.map((field) => {
      if (field.key === "Year") {
        // Extract existing years from the options
        const existingYears = field.options.split("#;#").map((year: any) => parseInt(year.trim(), 10));

        // Generate a range of years from the smallest year in options to the current year
        const minYear = Math.min(...existingYears, currentYear);
        const maxYear = Math.max(...existingYears, currentYear);

        const updatedYearOptions = Array.from({ length: maxYear - minYear + 1 }, (_, i) => (minYear + i).toString())
          .join("#;#");
        setYearChoices(updatedYearOptions);
        return {
          ...field,
          options: updatedYearOptions, // Update the options dynamically
        };
      }
      return field;
    });

    formFields = [...updatedFields];
    let template = undefined;
    //debugger;
    if (formMode === "Edit" || formMode === "View") {
      const item = props.selectedRow[0] && props.selectedRow[0];
      formFields = updatedFields.map((field) => {
        const defaultValue = item[field.key] ? item[field.key] : "";
        if(field.key === 'Template')
            {template = defaultValue}
        return { ...field, defaultValue };
      })

    }
    setFields(formFields);
    /*setRenderKey((prev) => prev + 1);
    const defaultValues: any = {};
    formFields.forEach((field) => {
      defaultValues[field.key] = field.defaultValue || "";
    });
    reset(defaultValues);*/

    setLoadForm(true);
    const handleTemplateChangeUseEffect = (value: string) => {
      const updatedFields = formFields.map((field) => {
        if (field.key === "Month") {
          return { ...field, hidden: value !== "Monthly", required: value === "Monthly" };
        } else if (field.key === "Quarter") {
          return { ...field, hidden: value !== "Quarterly", required: value === "Quarterly" };
        } else if (field.key === "HalfYear") {
          return { ...field, hidden: value !== "Bi-Annual", required: value === "Bi-Annual" };
        } else if (field.key === "ReportingPeriod") {
          return {
            ...field,
            hidden: !value || ["Monthly", "Quarterly", "Bi-Annual"].includes(value), // Hide if template is empty/null or matches Monthly/Quarterly/Bi-Annual
            required: value && !["Monthly", "Quarterly", "Bi-Annual"].includes(value), // Required only if template is not empty/null and doesn't match Monthly/Quarterly/Bi-Annual
          };
        }
        return field;
      });
  
      // Reset the fields
      resetField("Month");
      resetField("Quarter");
      resetField("HalfYear");
      resetField("ReportingPeriod");
  
      setFields(updatedFields); // Update state with the modified fields
    };
    handleTemplateChangeUseEffect(template);
  }, [formMode, props.selectedRow, props.fields]);



  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();

  const onSubmit = async (d: any) => {

    const data = { ...d };
    //setD(JSON.stringify(data));
    let oItem = data;
    const result = Object.keys(data).map((key) => {
      const column = fields.find((c) => c.key === key);
      if (!column) {
        return null;
      }
      const value = data[key];
      const dataType = column.datatype.toLowerCase();
      if (dataType === 'datetimes') {
        delete oItem.key;
        oItem = { ...oItem, key: value };
      }
      const formattedValue =
        dataType === 'strings'
          ? value
          : dataType === 'numbers'
            ? Number(value)
            : dataType === 'datetimes'
              ? value
              : null;

      return {
        column_Name: column.key,
        [`${dataType}_Value`]: formattedValue,
        dataype: dataType,
      };
    }).filter((x) => x !== null);

    let response: any;
    if (formMode === 'Edit') {
      const accessToken: any = await getAccessToken();
      response = await updateItem(props.logTitle, accessToken, clientAPIURL, props.siteId, itemId, result);

    } else {
      const accessToken: any = await getAccessToken();
      response = await createItem(props.logTitle, accessToken, clientAPIURL, props.siteId, result);
    }

    //if(response.status === 401 || response.statusText === "Unauthorized" || response.statusText === "Bad Request")
    if (!response) {
      setToastText("Operation Failed: You are not authorized to perform this action.")
      setToastVisibility(true);
    }
    if (response) {
      setToastText("Your changes are saved.")
      setToastVisibility(true);
      //const dataArray = [data];
      setTimeout(function () {
        if (formMode === 'Edit') {

          if (oItem) {
            oItem["ID"] = itemId
            oItem["key"] = itemId
          }
          const createdItem = oItem
          props.ItemCreated(createdItem);
        }
        else {
          const createdItem = oItem
          if (oItem) {
            oItem["ID"] = response
            oItem["key"] = response
          }
          props.ItemCreated(createdItem);
        }
      }, 2000)
    }
  };
  const invalidNumber = () => {
    setToastText("Invaid Entry: Please enter valid number to numerical column.")
    setToastVisibility(true);
  }

  const handleClose = () => {
    props.closeForm()
  }

  const handleToastDismiss = () => {
    setToastVisibility(false);
  };

  const handleTemplateChange = (value: string) => {
    const updatedFields = fields.map((field) => {
      if (field.key === "Month") {
        return { ...field, hidden: value !== "Monthly", required: value === "Monthly" };
      } else if (field.key === "Quarter") {
        return { ...field, hidden: value !== "Quarterly", required: value === "Quarterly" };
      } else if (field.key === "HalfYear") {
        return { ...field, hidden: value !== "Bi-Annual", required: value === "Bi-Annual" };
      } else if (field.key === "ReportingPeriod") {
        return {
          ...field,
          hidden: !value || ["Monthly", "Quarterly", "Bi-Annual"].includes(value), // Hide if template is empty/null or matches Monthly/Quarterly/Bi-Annual
          required: value && !["Monthly", "Quarterly", "Bi-Annual"].includes(value), // Required only if template is not empty/null and doesn't match Monthly/Quarterly/Bi-Annual
        };
      }
      return field;
    });

    // Reset the fields
    resetField("Month");
    resetField("Quarter");
    resetField("HalfYear");
    resetField("ReportingPeriod");

    setFields(updatedFields); // Update state with the modified fields
  };


  const fieldStyle = formMode === "View" ? formCustomStyles.fieldContianerViewMode : formCustomStyles.fieldContianer
  return (
    loadForm === false ? null :
      <View style={formStyle} key={renderKey}>
        <ScrollView>
          <>
            <View style={[{ flex: 1, margin: 10 }, styles.buttonsContainer]}>
              {
                formMode === "View"
                  ?
                  <View>
                    <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  </View>
                  :
                  <ScrollView horizontal>
                    <View style={styles.buttonsContainer}>
                      <View style={{}}>
                        <ButtonControl icon="content-save-edit" mode="contained" onPress={handleSubmit(onSubmit)} Title='Submit' />
                      </View>
                      <View style={{}}>
                        <ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' />
                      </View>
                    </View>
                  </ScrollView>
              }
            </View>

            <View style={formCustomStyles.allFieldsContainer}>
              {fields.map((field, index) => {
                if (field.hidden === true) {
                  return null
                }
                else if (field.key === "Template") {
                  return (
                    <View key={index} style={[fieldStyle]}>
                      <View key={index} style={[fieldStyle]}>
                        <ReactDropdownSingleSelect
                          field={field}
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          control={control}
                          Ctrl={Controller}
                          errors={errors}
                          changeEvent={handleTemplateChange}
                        />
                      </View>
                    </View>
                  );
                }
                else if (field.key === "Year") {
                  return (
                    <View key={index} style={[fieldStyle]}>
                      <ReactDropdownSingleSelect
                        field={field}
                        key={field.key.toString()}
                        editable={formMode === 'View' ? false : true}
                        control={control}
                        Ctrl={Controller}
                        errors={errors}
                        choices={yearChoices}
                      />
                    </View>
                  );
                }
                else if (field.key === "Month" && selectedTemplate === "Monthly") {
                  return (
                    selectedTemplate === "Monthly" ?
                      <View key={index} style={[fieldStyle]}>
                        <ReactDropdownSingleSelect
                          field={field}
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          control={control}
                          Ctrl={Controller}
                          errors={errors}
                        />
                      </View> : null
                  );
                }
                else if (field.key === "Quarter" && selectedTemplate === "Quarterly") {
                  return (
                    selectedTemplate === "Quarterly" ?
                      <View key={index} style={[fieldStyle]}>
                        <ReactDropdownSingleSelect
                          field={field}
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          control={control}
                          Ctrl={Controller}
                          errors={errors}
                        />
                      </View>
                      : null
                  );
                }
                else if (field.key === "HalfYear" && selectedTemplate === "Bi-Annual") {
                  return (
                    selectedTemplate === "Bi-Annual" ?
                      <View key={index} style={[fieldStyle]}>
                        <ReactDropdownSingleSelect
                          field={field}
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          control={control}
                          Ctrl={Controller}
                          errors={errors}
                        />
                      </View> :
                      null
                  );
                }
                else if (field.key === "ReportingPeriod" && selectedTemplate && !["Monthly", "Quarterly", "Bi-Annual"].includes(selectedTemplate)) {
                  return (
                    selectedTemplate && !["Monthly", "Quarterly", "Bi-Annual"].includes(selectedTemplate) ?
                      <View key={index} style={[fieldStyle]}>
                        <ReactDropdownSingleSelect
                          field={field}
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          control={control}
                          Ctrl={Controller}
                          errors={errors}
                        />
                      </View>
                      : null
                  );
                }
                else {
                  switch (field.subType) {
                    case 'text':
                      return (
                        // <Text>{field.key}</Text>
                        <View key={index} style={[fieldStyle]}>
                          <TextField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          />
                        </View>
                      );
                    case 'choice':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <ReactDropdownSingleSelect
                            field={field}
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            control={control}
                            Ctrl={Controller}
                            errors={errors}
                          />
                        </View>
                      );
                    case 'float':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <NumberField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                            logTitle={props.logTitle}
                            fields={fields}
                            setFields={setFields}
                            invalidNumber={invalidNumber}
                          //ref={register}
                          /></View>
                      );
                    case 'integer':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <NumberField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                            logTitle={props.logTitle}
                            fields={fields}
                            setFields={setFields}
                            invalidNumber={invalidNumber}
                          //ref={register}
                          /></View>
                      );
                    case 'date':
                      return (
                        <View key={index} style={[fieldStyle]}>
                          <PaperDates
                            key={field.key.toString()}
                            field={field}
                            editable={formMode === 'View' ? false : true}
                            control={control}
                            styles={styles.date}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          /></View>
                      );
                    default:
                      return null
                  }
                }
              }
              )}
            </View>
            <View style={{ margin: 10 }}>
              {
                formMode === "View"
                  ?
                  <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  :
                  <ScrollView horizontal style={{ width: '100%' }}>
                    <View style={styles.buttonsContainer}>
                      <View style={{}}><ButtonControl icon="content-save-edit" mode="contained" onPress={handleSubmit(onSubmit)} Title='Submit' /></View>
                      <View style={{}}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                    </View>
                  </ScrollView>
              }
            </View>
            <View>
              <ToastMessage
                show={toastVisibility}
                actionLabel="Dismiss"
                toastMessage={toastText}
                duration={3000}
                onDismiss={handleToastDismiss}
              />
            </View>
          </>
        </ScrollView>
      </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //justifyContent: 'center',
    //alignItems: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',

    flex: 1,
    padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  formContainerPC: {
    paddingLeft: '25%',
    paddingRight: '25%',
  },
  formContainerMobile: {
    borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    backgroundColor: '#FFFFFF',
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4,
    //marginLeft: '5%',
    //marginRight: '5%',
    //paddingLeft: '2%',
    //paddingRight: '2%',
  },
  input: {
    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4,
    minHeight: 60,
    maxHeight: 60,
  },
  date: {
    minWidth: '100%',
    minHeight: 60,
    maxHeight: 60,

    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4
  },
  buttonsContainer: {
    flex: 1,
    //padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    //alignItems: 'center',
    //justifyContent: 'space-evenly',
    height: 60
  }
});

export default ReportRequestForm;
